<template>
  <div>
    <div class="text-left">
      <h1 style="text-align: center;
                 padding: 0 1% 0 1%;">{{ $t('WantToTestACarYourselfGetTheUcarOKConnector') }}</h1>
      <div class="row" style="margin-bottom:12px">
        <div class="col-md-6" style="padding: 0 7% 0 7%;">
          <p>
           {{ $t('BeforeYouBuyKnowThatTheCarYouAreLooking') }}
          </p>
          <video style="width: 100%" controls>
            <source src="https://s3.amazonaws.com/ucarok/web/videos/ucarok_introduction.mp4"
                    type="video/mp4">
          </video>
        </div>
        <div class="col-md-6"
             style="padding: 0 4% 0 0;
                    margin-top: 30px;">
          <img src="../assets/images/icons/obd.png"
               style="width: 75%;
                      margin: auto;
                      display: block;
                      ">
          <div style="width: 100%;
                      margin-top: 30px;
                      display: flex;
                      justify-content: center;">
            <a target="_blank" class="btn btn-new-green"
               href="https://www.amazon.com/dp/B07GZTV3WX">{{ $t('BuyItOnAmazon') }} </a>
          </div>
        </div>
      </div>
      <p style="font-size:20px;
                text-align: center;
                padding: 0 1% 0 1%;
                margin-bottom: 30px;">
        {{ $t('FirstCallToOurHotlineSupportLine') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObdConnector',
};
</script>

<style scoped>
  .btn-new-green {
    background-color: #10925D;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 35px;
    border-radius: 0;
    font-weight: bold;
    text-decoration: none;
  }
</style>
