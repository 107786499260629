var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-left" }, [
      _c(
        "h1",
        { staticStyle: { "text-align": "center", padding: "0 1% 0 1%" } },
        [_vm._v(_vm._s(_vm.$t("WantToTestACarYourselfGetTheUcarOKConnector")))]
      ),
      _c(
        "div",
        { staticClass: "row", staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "div",
            { staticClass: "col-md-6", staticStyle: { padding: "0 7% 0 7%" } },
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("BeforeYouBuyKnowThatTheCarYouAreLooking")) +
                    " "
                ),
              ]),
              _vm._m(0),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-md-6",
              staticStyle: { padding: "0 4% 0 0", "margin-top": "30px" },
            },
            [
              _c("img", {
                staticStyle: { width: "75%", margin: "auto", display: "block" },
                attrs: { src: require("../assets/images/icons/obd.png") },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "margin-top": "30px",
                    display: "flex",
                    "justify-content": "center",
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-new-green",
                      attrs: {
                        target: "_blank",
                        href: "https://www.amazon.com/dp/B07GZTV3WX",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("BuyItOnAmazon")) + " ")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "p",
        {
          staticStyle: {
            "font-size": "20px",
            "text-align": "center",
            padding: "0 1% 0 1%",
            "margin-bottom": "30px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("FirstCallToOurHotlineSupportLine")))]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "video",
      { staticStyle: { width: "100%" }, attrs: { controls: "" } },
      [
        _c("source", {
          attrs: {
            src: "https://s3.amazonaws.com/ucarok/web/videos/ucarok_introduction.mp4",
            type: "video/mp4",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }